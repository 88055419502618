import React from 'react'
import Header from '../components/global/Header'
import ListBlogs from '../components/Blogs/ListBlogs'
import { useTranslation } from 'react-i18next'

const Blogs = () => {
  const {t} = useTranslation()
  return (
      <>
          <Header title={t("Blogs")} />
          <ListBlogs />
      </>
  )
}

export default Blogs