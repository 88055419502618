import React from 'react'
import Header from '../components/global/Header'
import ListBuy from '../components/Buy/ListBuy'

const Buy = () => {
  return (
      <>
          <Header title={`Buy`} />
          <ListBuy />
      </>
  )
}

export default Buy